#root{
  display:flex;
  margin: 0 auto;
  height: 100vh;
  flex-direction: column;
}

.App, .Home{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.posts-container {
    column-count: 4;
    column-gap: 1rem;
    margin: 1.5rem;
  }

  .post {
    display: block;
    margin: 0 0 1rem;
    width: 100%;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    background-color: rgb(229, 229, 229);
    padding: 2rem 1.5rem 1rem 2rem;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 30px;
    position: relative;
    /* text-transform: lowercase; */
    /* font-style: italic; */
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid; /* need this for Safari, Chrome, and Opera */
  }

  .loading-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    padding: 50px;
    margin-bottom: 50px;
    flex-grow: 1;
   }

   .loading::before {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #000000; /* Blue */
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .load-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
  }

  #post-text{
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }

  .post-tag{
    display: flex;
    flex-wrap: wrap;
  }

  .tag, #Society, #Tech, #Social, #Fantasy, #Shitpost, #Business, #Art{
    font-size: 18px;
    border-radius: 50px;
    padding: 5px 12px;
    margin-right: 7px;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 12px;
  }

  .tag-buttons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  button#Society, button#Tech, button#Social, button#Fantasy, button#Shitpost, button#Business, button#Art {
    border: none;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 18px;
    padding: 5px 15px;
    margin-right: 7px;
    margin-bottom: 12px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 50px;
    /* text-transform: uppercase; */
  }

  #Society{
    color: rgb(255, 255, 255);
    background-color: rgb(0, 200, 23);
  }

  #Tech {
      color: rgb(255, 255, 255);
      background-color: rgb(33, 132, 253);
  }

  #Social {
      color: rgb(255, 255, 255);
      background-color: rgb(241, 40, 137);
  }

    #Fantasy {
        color: rgb(255, 255, 255);
        /* background-color: rgb(254, 154, 246); */
        background-image: linear-gradient(to right, rgb(111, 0, 196), rgb(255, 26, 247));
    }
      
  #Shitpost {
      color: rgb(255, 255, 255);
      background-color: rgb(95, 43, 0);
  }

  #Business {
      color: rgb(255, 255, 255);
      background-color: rgb(255, 149, 0);
  }

  #Art {
      color: rgb(255, 255, 255);
      background-color: rgb(255, 71, 71);
  }

  .filter-container{
    margin-left: 10px;
  }

  /* .sort-posts{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 30px;
    border-radius: 10px;
    border-bottom: #000000 2px solid;
    border-right: black 2px solid;
    background-color: rgb(229, 229, 229);
  } */

  /* .sort-posts:hover{
    background-color: rgb(0, 0, 0);
    color: white;
  } */

  select{
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    border: none;
    /* text-align: center; */
    border-radius: 10px;
    margin-right: 10px;
    background-color: rgb(229, 229, 229);
    color: rgb(0, 0, 0);
    border-right: 16px solid transparent;
    border-left: 16px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  select:focus {
    outline: none; /* remove default outline */
  }

  select:hover{
    background-color: rgb(0, 0, 0);
    color: white;
    cursor: pointer;
  }


  #arrow{
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .upvote-container{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* position:absolute; */
    /* bottom: 10%;
    right: 5%; */
  }

  #upvote-no{
    margin: 0;
    font-size: 20px;
  }

  p{
    padding-top: 0;
  }

  .new-post-button{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 2%;
    bottom: 4%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: none;
    background-color: black;
    color: white;
    font-size: 30px;
    cursor: pointer;
    font-size: 40px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 25%;
  }

    /* .modal-content form{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 50px;
        border-radius: 10px;
        border: none;
        margin-bottom: 20px;
        padding-left: 10px;
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 20px;
    } */
/* 
    textarea{
        height: 300px;
    } */

    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
        margin-left: 5px;
        margin-right: 5px;
    }
  
    textarea{
        margin-bottom: 30px;
        border:none;
        background-color: rgb(229, 229, 229);
        border-radius: 5px;
        font-family: 'Inter', sans-serif;
        font-size: 30px;
        padding: 15px;
        /* text-transform: uppercase; */
        font-weight: bold;
        width: 96%;
        /* letter-spacing: 0.2px;
        word-spacing: 1px */
    }

    #post{
        border-radius: 5px;
        border: none;
        background-color: black;
        color: white;
        font-size: 20px;
        padding: 10px;
        font-family: 'inter', sans-serif;
        font-weight: bold;
        width: 80%;
        align-items: center;
        justify-content: center;
    }

    /* #post:hover{
        background-color: rgb(0, 0, 0, 0.8);
    } */

    .tag-filter{
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 40px;
        /* width: max-content;  */
    }

    #filter{
      padding: 10px 20px;
      margin-right: 12px;
      font-family: 'Nunito', sans-serif;
      border-radius: 20px;
      border: rgb(100, 100, 100) 2px solid;
      cursor: pointer;
      align-items: center;
      font-size: 15px;
      color: black;
    }

    #filter:hover{
      background-color: rgb(100, 100, 100);
      color: white;
    }

    #filter.selected {
      background-color: rgb(0, 0, 0);
      color: white;
      border: black 2px solid;
    }

    .search-container {
      position: relative;
      width: 280px; /* adjust as needed */
      background-color: rgb(229, 229, 229);
      border-radius: 10px ;
      border: none;
      margin-left: 5px;
    }

    .search-bar{
      padding: 20px 15px;
      border: none;
      border-radius: 10px;
      background-color: transparent;
      width: 220px;
      font-size: 20px;
      font-family: 'inter', sans-serif;
      font-weight: bold;
    }

    .search-bar:focus {
      outline: none; /* remove default outline */
    }

    .search-icon{
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      color: rgb(100, 100, 100);
    }

  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    background-color: black;
    font-size: 20px;
    cursor: pointer;
  }

  a{
    text-decoration: none;
    color: white;
    font-family: 'inter', sans-serif;
    cursor: pointer;
    font-weight: bold;
  }

  #footer{
    color: white;
    font-family: 'inter', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  footer{
    text-align: center;
    padding: 20px;
    left: 0;
    bottom: 0;
    width: -webkit-fill-available;
    background-color: black;
  }
  
  @keyframes bubble {
    0% { opacity: 0; transform: translateY(100px); }
    50% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-100px); }
  }

  /* loading animation */

  @media(max-width: 1300px){
    .sort-posts{
      margin-right: 0px;
    }
  }
  

  @media(max-width: 1200px){
    .posts-container {
      column-count: 3;
      column-gap: 1rem;
      margin: 1.5rem;
    }

    .posts-container {
        column-count: 3;
        column-gap: unset;
        margin: 1rem;
        width: -webkit-fill-available;
      }

    .post{
        font-size: 30px;
    }

    .tag-filter{
      justify-content: center;
      margin-bottom: 25px;
    }

    .filter-container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 20px;
    }

    #filter{
      margin-bottom: 15px;
      margin-right: 5px;
    }

    .search-container {
      margin-left: 0px;
      margin-bottom: 10px;
    }

    .search-bar{
      padding: 15px 15px;
      border: none;
      border-radius: 10px;
      background-color: transparent;
      width: 220px;
      font-size: 14px;
      font-family: 'inter', sans-serif;
      font-weight: bold;
    }

    .sort-posts{
      margin-top: 15px;
      margin-left: 0px;
      margin-right: 0px;
      padding: 5px;
    }
  }

  
  @media(max-width: 920px){
    .filter-container{
      margin-bottom: 10px;
    }


    .sort-posts{
      margin-top: 0px;
      margin-left: 10px;
    }
  }

  @media (max-width: 700px) {

    .modal-content {
      width: 80%;
    }

    .posts-container {
        column-count: 2;
        column-gap: unset;
        margin: 1rem;
        width: -webkit-fill-available;
      }

    .post{
        width: -webkit-fill-available;
        font-size: 20px;
        box-sizing: content-box;
        padding: 1.2rem 1rem 1rem 1.2rem;
        break-inside: avoid;
        -webkit-column-break-inside: avoid;
        /* padding: 10px 65px 10px 20px; */
    }

    #post-text{
        margin: 0;
    }

    .post-footer{
        display: flex;
        align-items: center;
    }

    .new-post-button{
        width: 80px;
        height: 80px;
        font-size: 30px;
        right: 4%;
        bottom: 2%;
    }

    svg{
        width: 20px;
        height: 20px;
    }

    .post-tag{
        display: block;
    }

    .tag, #Society, #Tech, #Social, #Fantasy, #Shitpost, #Business, #Art, #filter{
        font-size: 13px;
        margin-right: 0;
        margin-bottom: 5px;
    }
    
      .upvote-container{
        display: flex;
        align-items: center;
        position:absolute;
        bottom: 5%;
        right: 5%;
      }
    
      #upvote-no{
        margin: 0;
        font-size: 15px;
      }

      #footer{
        font-size: 14px;
      }   

      .tag-filter{
        justify-content: center;
        margin-bottom: 25px;
      }

      .filter-container{
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 0px;
      }

      #filter{
        margin-bottom: 15px;
        margin-right: 5px;
      }

      .search-container {
        margin-left: 0px;
        margin-bottom: 10px;
      }
  
      .search-bar{
        padding: 15px 15px;
        border: none;
        border-radius: 10px;
        background-color: transparent;
        width: 220px;
        font-size: 14px;
        font-family: 'inter', sans-serif;
        font-weight: bold;
      }
  }