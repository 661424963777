@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');

.header{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    margin-bottom: 40px;
}

.header p{
    align-items: end;
    font-size: 20px;
    margin-top: 5px;
    padding-right: 10px;
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
}

#name{
    margin: 0;
}

.header h1{
    font-size: 50px;
    font-family: 'Nunito', sans-serif;
}

a{
    text-decoration: none;
    color: white;
}

#logout{
    background-color: rgb(0, 0, 0);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    margin-top:10px;
    cursor: pointer;
}

.navbar {
    font-family: 'Nunito', sans-serif;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.navbar-logo {
    margin-right: 20px; /* adjust as needed */
    margin-left: 10px; /* adjust as needed */
    cursor: pointer;
    width: 185px;
  }

@media (max-width: 600px) {
    .header h1{
        font-size: 30px;
    }
    .header p{
        font-size: 15px;
    }
    .navbar-logo {
        margin-top: 10px;
        width: 130px;
        margin-right: 0px; /* adjust as needed */
        margin-left: 0px; /* adjust as needed */
        cursor: pointer;
        align-items: center;
        flex: unset;
      }

    .login-detail{
        display:none;
    }

    .header{
        align-items: center;
        margin-bottom: 0;
    }

    .navbar{
        flex: unset;
    }
}

